.form-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
  }
  
  .form-paper {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .form-heading {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .MuiTextField-root {
    margin-bottom: 15px;
  }
  
  .submit-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #d32f2f;
  }
  