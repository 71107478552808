.organizer-list-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.organizer-list-container h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.add-organizer-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-organizer-btn:hover {
  background-color: #d32f2f;
}

.organizer-table {
  width: 100%;
  border-collapse: collapse;
}

.organizer-table th, 
.organizer-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.organizer-table th {
  background-color: #f44336;
  color: white;
}

.edit-btn, .delete-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn {
  background-color: #4caf50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.edit-btn:hover {
  background-color: #388e3c;
}

.delete-btn:hover {
  background-color: #d32f2f;
}
