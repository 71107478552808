/* General Footer Styles */
.footer {
  background-color: #2C2C2C;  /* Dark charcoal for better contrast */
  color: #E0E0E0; /* Light gray text for contrast */
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow for separation */
}

/* Container to organize footer elements */
.footer-container {
  width: 100%;
  max-width: 1200px; /* Max width for large screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Footer Text Section */
.footer-text {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: #E0E0E0; /* Light gray text */
}

/* Social Media Links Section */
.footer-social {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
  margin-bottom: 20px;
}

.footer-social a {
  color: #E0E0E0; /* Light gray icons */
  font-size: 30px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #FF4081; /* Pinkish hover effect for visual appeal */
}

/* Footer Button Section */
.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-btn {
  font-size: 16px;
  color: #FFFFFF; /* White text */
  background-color: #FF4081; /* Pink button color */
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.footer-btn:hover {
  background-color: #D500F9; /* Purple hover effect */
  transition: background-color 0.3s ease;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-social {
    gap: 10px; /* Adjust space between icons */
  }

  .footer-buttons {
    flex-direction: column;
    gap: 10px;
  }
}
