/* General Layout */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f9f9f9;
}

.dashboard-container {
  max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
}

/* Header */
.dashboard-header h1 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Stats and Quick Actions */
.stats-actions-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.stat-box {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-box p {
  font-size: 16px;
  margin: 0;
  color: #555;
}

.stat-box h2 {
  font-size: 36px;
  margin: 10px 0;
  color: #f44336; /* Red color for statistics */
}

.action-btn {
  background-color: #f44336; /* Red button */
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.action-btn:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Actions and Upcoming Events Section */
.actions-events-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}


.upcoming-events {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 280px; /* Set a minimum width for each section */
}


.upcoming-events h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.quick-actions {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quick-actions h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.actions-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.actions-buttons button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.actions-buttons button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

.actions-buttons button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #d32f2f(0, 123, 255, 0.5);
}


.actions-buttons button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Upcoming Events List Styling */
.upcoming-events ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.upcoming-events li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: #555;
}

.upcoming-events li:last-child {
  border-bottom: none; /* Remove last border */
}

.event-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.event-details strong {
  font-size: 16px;
  color: #333;
}

.event-details span {
  font-size: 14px;
  color: #888;
}
