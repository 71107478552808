/* styles.css or your preferred CSS file */

/* Common styles for both pages */
body {
  font-family: Arial, sans-serif;
  /* background-color: #f2f2f2; */
  margin: 0;
  padding: 0;
  width: 100%;
}

.container {
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

