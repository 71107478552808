.contact-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.contact-form {
  margin-top: 20px;
}

.MuiTextField-root {
  margin-bottom: 16px;
}

.MuiButton-root {
  margin-top: 20px;
}

.MuiTypography-root {
  margin-bottom: 24px;
}

.MuiSnackbar-root {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
