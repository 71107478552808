/* Homepage.css */

.homepage {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px 40px; /* Adjusting padding for better spacing */
}

.homeSection {
    margin: 20px;
    padding: 20px;
    background-color: rgba(255, 153, 51, 0.25); /* Light Saffron */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
    width: 30%; /* Adjusted for better balance */
    justify-content: space-between;
    gap: 20px;
}

.homeSection h2 {
    font-size: 1.5rem;
    color: #800000; /* Deep Maroon */
}

.homeSection p {
    font-size: 1rem; /* Ensuring consistency */
    color: #666; /* Dark Gray */
}

.big-text {
    font-size: 36px;
    color: #FFD700; /* Gold */
    margin: 20px 0;
}

p {
    color: #666; /* Dark Gray */
    font-size: 18px;
}

.event, .venue {
    margin-top: 3.5rem;
}

h2 {
    font-size: 24px;
    color: #800000; /* Deep Maroon */
    margin-bottom: 10px;
}

/* Default styles for larger screens */
.sectionContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 3.5rem;
}

.homeSection {
    width: 30%; /* Adjusted for better balance */
}

/* Styles for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
    .sectionContainer {
        flex-direction: column;
        align-items: center;
    }

    .homeSection {
        width: 80%; /* Adjusted for mobile */
        margin-bottom: 20px; /* Spacing between sections */
    }

    .big-text {
        font-size: 28px;
    }

    h2 {
        font-size: 22px;
    }

    p {
        font-size: 16px;
    }
}
