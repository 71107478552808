/* User Management Page Styles */
.user-management-page {
  padding: 20px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.user-management-page header {
  text-align: center;
  margin-bottom: 20px;
}

.user-management-page header h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.user-management-page nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.textField {
  width: 300px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.user-table th {
  background-color: #f44336;
  color: white;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.pagination ul li button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination ul li button:hover {
  background-color: #d32f2f;
}
