.about-us-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-section {
  padding-bottom: 20px;
}

.para {
  margin-bottom: 20px;
}

.team-section, .testimonials-section {
  padding: 20px 0;
}

.team-member {
  text-align: center;
}

.testimonial {
  margin-bottom: 20px;
}
