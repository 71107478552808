/* General Wrapper */
.hallWrapper {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  /* Title Styling */
  .hallTitle {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Address Styling */
  .hallAddress {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #555;
  }
  
  .hallAddress svg {
    margin-right: 8px;
  }
  
  /* Images Styling */
  .hallImages {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .hallImg {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Capacity */
  .fpName {
    font-size: 1.1rem;
    font-weight: bold;
    color: #555;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .fpName svg {
    margin-left: 5px;
  }
  
  /* Hall Details */
  .hallDetails {
    margin-top: 20px;
    color: #333;
  }
  
  .hallDetails h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .hallDetails p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Button Styling */
  .hallDetailsPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #e53935;
  }
  