/* Signup.css */

.form-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 40px auto;
}

.form-container .MuiPaper-root {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container .MuiAvatar-root {
  margin-bottom: 10px;
  background-color: #f44336;
}

.form-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.form-container .MuiTextField-root {
  margin-bottom: 15px;
}

.form-container .MuiButton-root {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.form-container .MuiButton-root:hover {
  background-color: #0056b3;
}

.form-container .MuiTypography-body2 {
  margin-top: 10px;
}
