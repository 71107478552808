/* Venue List Container */
.venue-list-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Table for Venue List */
  .venue-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .venue-table th,
  .venue-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .venue-table th {
    background-color: #f44336;
    color: white;
  }
  
  .venue-table td {
    font-size: 14px;
  }
  
  /* Action Buttons in Venue Table (Edit/Delete) */
  .venue-table .edit-venue-button,
  .venue-table .delete-venue-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .venue-table .edit-venue-button {
    background-color: #4caf50;
    color: white;
  }
  
  .venue-table .delete-venue-button {
    background-color: #f44336;
    color: white;
  }
  
  .venue-table .edit-venue-button:hover {
    background-color: #388e3c;
  }
  
  .venue-table .delete-venue-button:hover {
    background-color: #d32f2f;
  }
  
  /* Empty State when no venues are available */
  .venue-list-container p {
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-top: 20px;
  }
  .add-venue-btn {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .add-venue-btn:hover {
    background-color: #388e3c;
  }
  /* Responsive Design for smaller screens */
  @media screen and (max-width: 768px) {
    .venue-table th,
    .venue-table td {
      font-size: 12px;
    }
  
    .venue-list-container p {
      font-size: 14px;
    }
  }
  
  