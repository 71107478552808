/* Venue Form Styling */
.venue-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Title for Venue Form */
  .venue-form h1 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Form Fields */
  .venue-form label {
    font-size: 16px;
    color: #555;
  }
  
  .venue-form input,
  .venue-form textarea,
  .venue-form select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .venue-form .add-venue-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
  }
  
  .venue-form .add-venue-button:hover {
    background-color: #d32f2f;
  }
  
  /* Responsive Design for smaller screens */
  @media screen and (max-width: 768px) {
    .venue-form {
      padding: 15px;
    }
  
    .venue-form input,
    .venue-form textarea,
    .venue-form select {
      font-size: 12px;
    }
  
    .venue-form .add-venue-button {
      padding: 8px;
    }
  }
  